import React from "react"
import { Container, Layout, SEO } from "src/sws-ui"
import "src/css/pages/_privacy-conditions.scss"
//import SecurityAPi from "../graphql/security"
import ReactMarkdown from "react-markdown"

const Security = ({ pageContext }) => {
  const data = pageContext.alldata.frontmatter
  // const securitypage = SecurityAPi()

  return (
    <Layout>
      <SEO title="Security" />
      <Container gridTemplateRows="14" className="security p-4">
        <h1 className="row-1 col-2-11">{data.title}</h1>
        <div className="row-2 col-2-11">
          <ReactMarkdown source={data.security_content} />
        </div>
      </Container>
    </Layout>
  )
}

export default Security
